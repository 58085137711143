import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import {
	Back,
	Button,
	Form,
	FormFooter,
	FormHeading,
	FormReview,
	FormStep,
	Input,
	Layout,
	MessageBox,
	Select,
} from '../components'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Controller, useForm } from 'react-hook-form'
import axios from 'axios'
import { API_ENDPOINT } from '../constants'
import SuccessIcon from '../img/success.svg'
import countries from '../data/countries.json'
import policyTypes from '../data/policy-types.json'
import { pushToDataLayer } from '../helpers'

type QuotePageTemplateProps = {
	title: string
}

export type FormData = {
	firstName: string
	surname: string
	contactNumber: string
	emailAddress?: string
	country: string
	policyType: string
}

export type FormattedFormData = FormData & {
	brokerEmails: string[]
}

const QuotePageTemplate: React.FC<QuotePageTemplateProps> = ({ title }) => {
	const { register, handleSubmit, control, errors, formState, triggerValidation, getValues } = useForm<FormData>()

	const [step, setStep] = useState<number>(1)
	const [submitting, setSubmitting] = useState<boolean>(false)

	useEffect(() => {
		window.scrollTo({ top: 0 })
	}, [step])

	const onSubmit = handleSubmit(async (data) => {
		setSubmitting(true)

		const formData: FormattedFormData = {
			...data,
			brokerEmails: countries.find((cty) => cty.name === data.country)?.intermediaries.map((int) => int.email) ?? [],
		}

		try {
			await axios.post(`${API_ENDPOINT}/quote`, formData)
		} catch (e) {
			console.log(e)
		} finally {
			setSubmitting(false)
		}

		setStep(3)
	})

	const handleStep = (step: number) => async () => {
		await triggerValidation()

		if (Object.keys(errors).length === 0) {
			setStep(step)
		}
	}

	const setStatus = (fieldName: keyof FormData) => {
		if (errors[fieldName]) {
			return 'error'
		}

		if (formState.touched[fieldName] && errors[fieldName]) {
			return 'error'
		}

		if (formState.touched[fieldName]) {
			return 'successful'
		}
	}

	return (
		<div>
			<Form onSubmit={onSubmit}>
				<FormStep visible={step === 1}>
					<Back to={'/'} />
					<FormHeading>{title}</FormHeading>
					<Grid>
						<Input
							id={'first-name'}
							name={'firstName'}
							label={'First Name*'}
							placeholder={'First name'}
							title={'First name'}
							inputRef={register({ required: true })}
							message={errors.firstName && 'Please enter field'}
							status={setStatus('firstName')}
							onChange={() => triggerValidation('firstName')}
							onBlur={() => triggerValidation('firstName')}
						/>
						<Input
							id={'surname'}
							name={'surname'}
							label={'Surname*'}
							placeholder={'Surname'}
							title={'Surname'}
							inputRef={register({ required: true })}
							message={errors.surname && 'Please enter field'}
							status={setStatus('surname')}
							onChange={() => triggerValidation('surname')}
							onBlur={() => triggerValidation('surname')}
						/>
						<Input
							id={'number'}
							name={'contactNumber'}
							label={'Contact Number*'}
							placeholder={'+1'}
							title={'Contact number'}
							inputRef={register({ required: true, pattern: /[0-9 ]+/ })}
							message={errors.contactNumber && 'Please enter field'}
							status={setStatus('contactNumber')}
							onChange={() => triggerValidation('contactNumber')}
							onBlur={() => triggerValidation('contactNumber')}
						/>
						<Input
							id={'email'}
							name={'emailAddress'}
							label={'Email Address'}
							placeholder={'Email Address'}
							title={'Email address'}
							type={'email'}
							inputRef={register({
								pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
							})}
							status={setStatus('emailAddress')}
						/>
						<Controller
							id={'country'}
							as={Select}
							name={'country'}
							control={control}
							label={'Country*'}
							placeholder={'Select country'}
							options={countries.map(({ name }) => name)}
							onChange={([{ value }]) => {
								pushToDataLayer('country', value)
								return value
							}}
							message={errors.country && 'Please select a country'}
							rules={{ required: true }}
						/>
						<Controller
							id={'policyType'}
							as={Select}
							name={'policyType'}
							control={control}
							label={'Type of Policy*'}
							placeholder={'Select policy'}
							options={policyTypes}
							onChange={([{ value }]) => value}
							message={errors.policyType && 'Please select a policy type'}
							rules={{ required: true }}
						/>
					</Grid>
					<FormFooter>
						<Button
							type='button'
							variant='secondary'
							displayBlockOnMobile
							fixedSize
							onClick={handleStep(2)}
							id='gtm-quote-continue'
						>
							Continue
						</Button>
					</FormFooter>
				</FormStep>
				<FormStep visible={step === 2}>
					<Back onClick={() => setStep(1)} />
					<FormHeading subheading='Please review details' center>
						{title}
					</FormHeading>
					{step === 2 && (
						<FormReview fields={getValues()}>
							<Row center={'md'}>
								<Col xs md={8} style={{ textAlign: 'right' }}>
									<Button type='button' variant='transparent' underline onClick={() => setStep(1)} xp={0}>
										Edit
									</Button>
								</Col>
							</Row>
						</FormReview>
					)}
					<MessageBox>
						If the above information is correct, please proceed to submit. Alternatively, use the edit button to correct
						your submission before proceeding to submit.
					</MessageBox>
					<FormFooter>
						<Button type='submit' displayBlockOnMobile fixedSize disabled={submitting} id='gtm-quote-submit'>
							{submitting ? 'Submitting...' : 'Submit'}
						</Button>
					</FormFooter>
				</FormStep>
				<FormStep visible={step === 3}>
					<Grid>
						<Row center='xs'>
							<Col>
								<SuccessIcon style={{ marginBottom: '1rem', marginTop: '1rem' }} />
							</Col>
						</Row>
					</Grid>
					<FormHeading
						subheading='Thank you.'
						center
						p={
							<>
								Your request for a {getValues().policyType} quote has been sent.
								<br /> The intermediaries will be in touch with you soon.
							</>
						}
					>
						Quote Request Submitted
					</FormHeading>
					<Grid>
						<Row center='xs'>
							<Col>
								<Button variant='secondary' type='button' fixedSize={true} as={Link} to={'/'} id='gtm-quote-finish'>
									Finish
								</Button>
							</Col>
						</Row>
					</Grid>
				</FormStep>
			</Form>
		</div>
	)
}

type ReportPageProps = {
	data: {
		markdownRemark: {
			frontmatter: QuotePageTemplateProps
		}
	}
}

const ReportPage: React.FC<ReportPageProps> = ({
	data: {
		markdownRemark: { frontmatter },
	},
}) => {
	return (
		<Layout useGrayBG padMain={false}>
			<QuotePageTemplate {...frontmatter} />
		</Layout>
	)
}

const pageQuery = graphql`
	query QuotePageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "quote-page" } }) {
			frontmatter {
				title
			}
		}
	}
`

export default ReportPage
export { QuotePageTemplate, pageQuery }
